<template>
  <div class="sign-in">
    <div class="form-box has-text-centered">
      <h3>You have been signed out.</h3>
      <router-link class="button is-dark" to="/">Return to Homepage</router-link>
    </div>
  </div>
</template>

<script type="es6">
import { mapActions } from 'vuex';

export default {

  metaInfo: {
    title: 'Signed Out'
  },

  mounted() {
    this.logout()
  },

  methods: {
    ...mapActions('user', ['logout'])
  }
}
</script>
